<template>
  <editable-control-wrapper
    :value="value"
    :has-label="floatingLabel"
    class="EditableControlStoryType"
  >
    <form-field
      :validator="validator"
      :attribute="attribute"
      :label="label"
      :hide-label="hideLabel"
      :name="name"
    >
      <el-select
        ref="input"
        v-model="computedValue"
        :placeholder="placeholder"
        clearable
        default-first-option
        popper-class="StoryEdit__StoryTypesModal"
        @change="$emit('save')"
      >
        <el-option
          v-for="item in storyTypes"
          :key="item"
          :label="$t('components.story_types.' + item)"
          :value="item"
        />
      </el-select>
    </form-field>
  </editable-control-wrapper>
</template>

<script>
import { STORY_TYPES_ARRAY } from '@/constants/story'
import EditableControlPrototype from '@hypefactors/shared/js/components/forms/controls/EditableControlPrototype'

/**
 * @module EditableControlStoryEdit
 */
export default {
  name: 'EditableControlStoryType',
  mixins: [EditableControlPrototype],
  computed: {
    storyTypes () {
      return STORY_TYPES_ARRAY
    }
  },
  methods: {
    focus () {
      this.$refs.input.toggleMenu()
    }
  }
}
</script>
